@font-face {
  font-family: 'IBM Plex Mono';
  src: url(./fonts/IBMPlexMono-Regular.ttf);
}

@font-face {
  font-family: 'Inter';
  src: url(./fonts/Inter-Light.ttf);
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #1e1e1e;
  color: white;
  background: radial-gradient(circle at bottom right, #48cfad -100%, #1e1e1e 70%);
  background-attachment: fixed;
}

*::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
  color: #48cfad;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #48cfad;
}

*::-webkit-scrollbar-thumb {
  background-color: #48cfad;
  border-radius: 8px;
}

*::-webkit-scrollbar-corner {
  background-color: #48cfad;
}

.editor-container > div {
  border-bottom-left-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}

.editor-container > div > div {
  border-bottom-left-radius: 40px !important;
  border-bottom-right-radius: 40px !important;
}
